<template>
  <div class="container-fluid">
    <div class="row mt-2">
      <div class="col-12 col-sm-6 col-sm-3">
        <label>Periodo</label>
        <v-date-picker v-model="dateRange" is-range class="text-center">
          <template v-slot="{ inputValue, inputEvents }">
            <div class="flex justify-center items-center">
              <input
                :value="`${inputValue.start} - ${inputValue.end}`"
                v-on="inputEvents.start"
                class="form-control text-center"
              />
            </div>
          </template>
        </v-date-picker>
      </div>
      <div class="col-12 col-sm-6 col-sm-3">
        <label>Centro(s) Custo</label>
        <select-centro-custo
          :multiple="true"
         @change="centro_custo"
          :showManager="false"
        />
        <!-- <v-select
          v-model="centro_custo"
          :options="centroCustos"
          value-field="cod_custo"
          label="nome"
          :reduce="(e) => e.cod_custo"
          :multiple="true"
        /> -->
      </div>
      <div class="col-12 text-right pt-4 d-flex justify-content-between">
        <b-btn variant="info" @click="openBaixaManual"
          ><b-icon-download /> Forçar Baixa de Pedidos</b-btn
        >
        <b-btn variant="success" @click="open(null)"
          ><b-icon-plus /> Novo Iventário</b-btn
        >
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-12">
        <b-table
          style="font-size: 11px"
          :fields="[
            { key: 'id', label: 'Código' },
            { key: 'data', label: 'Data' },
            { key: 'centro_custo', label: 'Centro(s) Custo' },
            { key: 'usuario', label: 'Responsável' },
            { key: 'sts', label: 'Status' },
          ]"
          small
          :items="lista"
          hover
          selectable
          select-mode="single"
          @row-selected="open"
          ref="tbLIST"
        >
          <template #cell(data)="row">
            {{ row.item.data | moment("DD/MM/YYYY HH:mm") }}
          </template>
          <template #cell(sts)="row">
            {{
              row.item.dt_finalizado && row.item.dt_finalizado !== ""
                ? "Finalizado"
                : "Em Andamento"
            }}
          </template>
        </b-table>
      </div>
    </div>
    <b-modal
      id="modal-inventario"
      hide-footer
      hide-header
      no-close-on-backdrop
      no-close-on-esc
      no-enforce-focus
      size="lg"
      :modal-class="`modal-full p-0   `"
      :content-class="`p-0 ${
        $store.state.isMobile ? `fixed modal-overflow-y` : ''
      }  `"
      :hide-header-close="$store.state.isMobile"
    >
      <InventarioForm @close="closeModal" :editObj="editObj" />
    </b-modal>
    <b-modal
      id="modal-baixa-manual"
      title="Baixa Manual de Pedidos"
      hide-footer
    >
      <baixa-manual />
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import InventarioForm from "./InventarioForm.vue";
import EstoqueLib from "../../libs/EstoqueLib";
// import CentrosDeCustoLib from "../../libs/CentrosDeCustoLib";
import BaixaManual from './BaixaManual.vue';
import SelectCentroCusto from '../../components/Financeiro/SelectCentroCusto.vue';

export default {
  components: { InventarioForm, BaixaManual, SelectCentroCusto },
  props: {},
  mounted() {
    // (async () => {
    //   this.centroCustos = await CentrosDeCustoLib.get();
    //   console.log(this.centroCustos);
    // })();
    this.carregar();
  },
  data() {
    return {
      centroCustos: [],
      dateRange: { start: moment().toString(), end: moment().toString() },
      centro_custo: [],
      lista: [],
      loading: false,
      editObj: null,
    };
  },
  watch: {
    dateRange: {
      deep: true,
      handler() {
        this.carregar();
      },
    },
    centro_custo: {
      deep: true,
      handler() {
        this.carregar();
      },
    },
  },
  computed: {},
  methods: {
    open(row) {
      console.log("selected", row);
      this.$refs.tbLIST.clearSelected();
      if (row == null || row.length > 0) {
        this.editObj = row !== null ? row[0] : null;
      }
      this.$bvModal.show("modal-inventario");
    },
    closeModal() {
      console.log("call close");
      this.$bvModal.hide("modal-inventario");
      this.carregar();
    },
    async carregar() {
      this.loading = true;
      this.lista = [];
      try {
        let res = await EstoqueLib.getListaInvetario({
          dt_i: moment(this.dateRange.start).format("YYYY-MM-DD"),
          dt_f: moment(this.dateRange.end).format("YYYY-MM-DD"),
          centro_custos: this.centro_custo,
        });
        console.log("RES", res);
        if (res && res.success) {
          this.lista = res.data;
        }
      } catch (error) {
        console.log("ERROR ON GET LISTA", error);
      }
    },
    openBaixaManual() {
      this.$bvModal.show("modal-baixa-manual");
    },
  },
};
</script>

<style lang="scss" scoped></style>
