<template>
  <div class="p-2">
    <h4>Selecione o Periodo</h4>
    <div class="d-flex">
      <b-input type="date" class="mr-2" v-model="dateRange.start"></b-input>
      <b-input type="date" class="ml-2" v-model="dateRange.end"></b-input>
    </div>
    <div>
      <b-btn
        variant="success"
        class="mt-2"
        block
        :disabled="loading"
        @click="baixar"
      >
        <span v-if="loading">
          <b-spinner small></b-spinner>
        </span>
        <span v-else> Baixar </span>
      </b-btn>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import EstoqueLib from "../../libs/EstoqueLib";
export default {
  components: {},
  props: {},
  mounted() {},
  data() {
    return {
      centroCustos: [],
      dateRange: {
        start: moment().format("YYYY-MM-DD"),
        end: moment().format("YYYY-MM-DD"),
      },
      centro_custo: [],
      lista: [],
      loading: false,
      editObj: null,
    };
  },
  watch: {},
  computed: {},
  methods: {
    async baixar() {
      this.loading = true;
      try {
        let res = await EstoqueLib.baixarManual({
          data: this.dateRange,
          centro_custo: this.centro_custo,
        });
        console.log('res', res);
        if (res && res.success) {
          this.showToast("success", "Baixa realizada com sucesso");
          this.loading = false;
          //this.closeModal();
        } else {
          this.showToast("error", "Erro ao baixar");
        }
      } catch (error) {
        this.showToast("error", "Erro ao baixar");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
