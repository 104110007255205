var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"col-12 col-sm-6 col-sm-3"},[_c('label',[_vm._v("Periodo")]),_c('v-date-picker',{staticClass:"text-center",attrs:{"is-range":""},scopedSlots:_vm._u([{key:"default",fn:function({ inputValue, inputEvents }){return [_c('div',{staticClass:"flex justify-center items-center"},[_c('input',_vm._g({staticClass:"form-control text-center",domProps:{"value":`${inputValue.start} - ${inputValue.end}`}},inputEvents.start))])]}}]),model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1),_c('div',{staticClass:"col-12 col-sm-6 col-sm-3"},[_c('label',[_vm._v("Centro(s) Custo")]),_c('select-centro-custo',{attrs:{"multiple":true,"showManager":false},on:{"change":_vm.centro_custo}})],1),_c('div',{staticClass:"col-12 text-right pt-4 d-flex justify-content-between"},[_c('b-btn',{attrs:{"variant":"info"},on:{"click":_vm.openBaixaManual}},[_c('b-icon-download'),_vm._v(" Forçar Baixa de Pedidos")],1),_c('b-btn',{attrs:{"variant":"success"},on:{"click":function($event){return _vm.open(null)}}},[_c('b-icon-plus'),_vm._v(" Novo Iventário")],1)],1)]),_c('hr'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('b-table',{ref:"tbLIST",staticStyle:{"font-size":"11px"},attrs:{"fields":[
          { key: 'id', label: 'Código' },
          { key: 'data', label: 'Data' },
          { key: 'centro_custo', label: 'Centro(s) Custo' },
          { key: 'usuario', label: 'Responsável' },
          { key: 'sts', label: 'Status' },
        ],"small":"","items":_vm.lista,"hover":"","selectable":"","select-mode":"single"},on:{"row-selected":_vm.open},scopedSlots:_vm._u([{key:"cell(data)",fn:function(row){return [_vm._v(" "+_vm._s(_vm._f("moment")(row.item.data,"DD/MM/YYYY HH:mm"))+" ")]}},{key:"cell(sts)",fn:function(row){return [_vm._v(" "+_vm._s(row.item.dt_finalizado && row.item.dt_finalizado !== "" ? "Finalizado" : "Em Andamento")+" ")]}}])})],1)]),_c('b-modal',{attrs:{"id":"modal-inventario","hide-footer":"","hide-header":"","no-close-on-backdrop":"","no-close-on-esc":"","no-enforce-focus":"","size":"lg","modal-class":`modal-full p-0   `,"content-class":`p-0 ${
      _vm.$store.state.isMobile ? `fixed modal-overflow-y` : ''
    }  `,"hide-header-close":_vm.$store.state.isMobile}},[_c('InventarioForm',{attrs:{"editObj":_vm.editObj},on:{"close":_vm.closeModal}})],1),_c('b-modal',{attrs:{"id":"modal-baixa-manual","title":"Baixa Manual de Pedidos","hide-footer":""}},[_c('baixa-manual')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }