<template>
  <div id="divPrint">
    <div class="row">
      <div class="col-3 col-sm-3 pr-1 pl-1 pr-sm-3 pl-sm-3">
        <label>Código</label>
        <b-input disabled v-model="obj.id" />
      </div>
      <div class="col-9 col-sm-3 pl-1 pr-1 pr-sm-3 pl-sm-3">
        <label>Data</label>
        <div disabled="disabled" class="form-control form-control disabled">
          {{ obj.data | moment("DD/MM/YYYY HH:mm") }}
        </div>
      </div>

      <div class="col-12 col-sm-6 pl-1 pr-1 pr-sm-3 pl-sm-3">
        <label>Centro(s) Custo</label>
        <select-centro-custo
          :multiple="true"
          :disabled="obj.id && obj.id.toString() != '' ? true : false"
          :showManager="false"
          @change="(ev)=>{obj.centro_custos = ev}"
          />
        <!-- <v-select
          :disabled="obj.id && obj.id.toString() != '' ? true : false"
          v-model="obj.centro_custos"
          :options="centroCustos"
          value-field="cod_custo"
          label="nome"
          :reduce="(e) => e.cod_custo"
          :multiple="false"
        /> -->
      </div>
      <div class="col-12 pt-3" v-if="obj && obj.usuario && obj.usuario != ''">
        Responsável: {{ obj.usuario }}
        <small class="text-right float-right" v-if="obj.usuarioFinalizou">
          Finalizado Por: {{ obj.usuarioFinalizou }} em
          {{ obj.dt_finalizado | moment("DD/MM/YYYY HH:mm") }}
        </small>
        <hr class="mb-1" />
      </div>
      <div class="col-12 mt-3" v-if="!loading && (!obj.id || obj.id == '')">
        <b-btn block variant="primary" :disabled="gerando" @click="gerar">
          <i class="fa fa-cogs" /> Gerar / Criar</b-btn
        >
        <b-btn variant="secondary" @click="$emit('close')" block
          ><b-icon-door-closed /> Cancelar / Sair</b-btn
        >
      </div>
    </div>
    <div
      v-if="!$store.state.isMobile && obj.id && obj.id != ''"
      class="d-flex justify-content-between"
    >
      <b-nav pills style="background-color: #f5f5f5" class="w-100 mt-2">
        <b-nav-item
          v-for="mn in opcs"
          :key="`opc_${mn.text}`"
          variant="outline-primary"
          class="mr-1"
          @click="mn.action"
          :disabled="
            mn.onEdit && obj.usuarioFinalizou && obj.usuarioFinalizou !== ''
          "
        >
          <span v-html="mn.icon"></span>
          {{ mn.text }}
        </b-nav-item>
      </b-nav>
    </div>
    <b-dropdown
      ref="dropdown"
      id="dropdown-1"
      variant="outline-primary"
      text="Opções"
      block
      class="m-md-2"
      menu-class="w-100"
      v-if="$store.state.isMobile && obj.id && obj.id != ''"
    >
      <b-dropdown-item
        style="height: 50px; width: 100%"
        v-for="mn in opcs"
        :key="`opcdd_${mn.text}`"
        @click="mn.action"
      >
        <span v-html="mn.icon"></span>
        {{ mn.text }}
        <hr />
      </b-dropdown-item>
    </b-dropdown>
    <hr />
    <div class="p-4 text-center" v-if="gerando">
      <b-spinner /><br />carregando...
    </div>
    <div class="text-center p-5" v-if="loading">
      <b-spinner />
    </div>
    <div v-if="obj && obj.dt_finalizado" class="pt-0 pb-3">
      <hr />
      <b-card title="Ajustes">
        <b-btn
          variant="info"
          block
          :disabled="ajustandoEstoque"
          @click="ajustarEstoque"
        >
          <b-spinner small v-if="ajustandoEstoque" /> Realizar Ajuste Estoque
        </b-btn>
        <b-table
          :items="obj.ajustes"
          :fields="[
            { key: 'data', label: 'Data/Hora' },
            { key: 'usuario', label: 'Usuário' },
            { key: 'opc', label: '' },
          ]"
          small
          style="font-size: 10px"
        >
          <template #cell(data)="row">
            {{ row.item.data | moment("DD/MM/YYYY HH:mm") }}
          </template>
          <template #cell(opc)="row">
            <b-btn size="sm" @click="openAjusteDetail(row)"
              ><b-icon-list /> Detalhes</b-btn
            >
          </template>
        </b-table>
      </b-card>
    </div>
    <div v-if="!gerando && obj && obj.estoque">
      <b-card
        body-class="d-flex justify-content-between p-1 bg-light pl-3 pr-3"
      >
        <small>
          Conferidos: {{ obj.estoque.filter((x) => x.status == "OK").length }}
        </small>
        <small>
          Divergência:
          {{
            obj.estoque.filter((x) => x.status == "OK" && x.qtd != x.qtd_conf)
              .length
          }}</small
        >
        <small>
          Faltam: {{ obj.estoque.filter((x) => x.status != "OK").length }}<br />
        </small>
      </b-card>

      <div class="pt-3 pb-3 d-flex justify-content-between">
        <b-btn
          variant="success"
          @click="addProd"
          v-if="obj && !obj.dt_finalizado"
          >Adicionar produto</b-btn
        >
        <b-input placeholder="filtrar" v-model="filter" class="w-50"></b-input>
      </div>
      <div class="" v-if="obj && obj.estoque">
        <b-table
          :responsive="Stacked"
          :filter="filter"
          :items="est"
          :fields="[
            { key: 'nome', label: 'Produto' },
            { key: 'qtd', label: 'Estoque', class: 'text-center' },
            { key: 'qtd_conf', label: 'Conferencia', class: 'text-center' },
            { key: 'dif', label: 'Dif.', class: 'text-center' },
            { key: 'status', class: 'text-center' },
          ]"
          :stacked="Stacked"
          ref="tb"
        >
          <template #cell(dif)="row">
            {{ getSinal(row) }}
            {{ (row.item.qtd - row.item.qtd_conf) * -1 }}
          </template>

          <template #cell(qtd_conf)="row">
            <div>
              <b-input-group
                v-if="
                  typeof obj.usuarioFinalizou == 'undefined' ||
                  !obj.usuarioFinalizou ||
                  obj.usuarioFinalizou == ''
                "
              >
                <b-form-input
                  pattern="\d*"
                  v-model="row.item.qtd_conf"
                  type="number"
                  class="text-center"
                  @click="resetItem(row.item)"
                ></b-form-input>
                <b-input-group-append v-if="row.item.status != 'OK'">
                  <b-btn variant="outline-info" @click="checkAndCopy(row.item)"
                    ><b-icon-border
                  /></b-btn>
                  <b-button variant="outline-success" @click="checkOk(row.item)"
                    ><b-icon-check-all
                  /></b-button>
                </b-input-group-append>
              </b-input-group>
              <div v-else>
                {{ row.item.qtd_conf }}
              </div>
            </div>
          </template>
        </b-table>
      </div>
    </div>
    <b-modal
      id="modal-add-prod"
      title="Adicionar Produto"
      hide-footer
      no-close-on-backdrop
      no-enforce-focus
    >
      <label>Selecione o Produto</label>
      <v-select
        :options="produtosDisponiveis"
        label="nome"
        v-model="produtoSelecionado"
      ></v-select>
      <div class="pt-2 w-100 text-right">
        <hr />
        <b-btn variant="success" class="mr-1" @click="adicionarProduto"
          >Adicionar</b-btn
        >
        <b-btn variant="secondary" @click="cancelProduto">Cancelar</b-btn>
      </div>
    </b-modal>
    <b-modal id="modal-ajuste-detail" hide-footer hide-title>
      <b-table
        small
        :items="ajusteDetalhe"
        :fields="[
          {
            key: 'produto',
            label: 'Produto',
          },
          { key: 'qtd_atual', label: 'Anterior' },
          { key: 'qtd_nova', label: 'Após' },
        ]"
      ></b-table>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import CentrosDeCustoLib from "../../libs/CentrosDeCustoLib";
import EstoqueLib from "../../libs/EstoqueLib";
import PrinterWindowHelper from "../../utils/PrinterWindowHelper";
import SelectCentroCusto from '../../components/Financeiro/SelectCentroCusto.vue';
export default {
  components: {SelectCentroCusto},
  props: {
    editObj: Object,
  },
  mounted() {
    (async () => {
      this.centroCustos = await CentrosDeCustoLib.get();
      console.log(this.centroCustos);
      if (this.editObj && this.editObj.id) {
        this.carregar(this.editObj);
      }
    })();
    EstoqueLib.getEstoqueAtual({}).then((x) => {
      if (x) {
        this.produtosDisponiveis = x;
      }
    });
  },
  data() {
    return {
      filter: "",
      centroCustos: [],
      obj: {
        id: "",
        data: moment().format("DD/MM/YYYY HH:mm:ss"),
        centro_custos: null,
      },
      Stacked: "sm",
      gerando: false,
      produtosDisponiveis: [],
      produtoSelecionado: null,
      opcs: [
        {
          onEdit: true,
          text: "Marcar Zerados OK",
          icon: '<i class="fa fa-check"></i>',
          action: () => {
            this.marcarZerados();
          },
        },
        {
          onEdit: true,
          text: "Salvar",
          icon: '<i class="fa fa-save"></i>',
          action: () => {
            console.log("salvando");
            this.salvar();
          },
        },
        {
          onEdit: true,
          text: "Salvar e Sair",
          icon: '<i class="fas fa-share-square"></i>',
          action: async () => {
            await this.salvar();
            this.$emit("close");
          },
        },
        {
          onEdit: true,
          text: "Finalizar Inventário",
          icon: '<i class="fas fa-share-square"></i>',
          action: () => {
            this.salvar(true);
          },
        },

        {
          onEdit: false,
          text: "Imprimir",
          icon: '<i class="fa fa-print"></i>',
          action: async () => {
            //  document.querySelector('#divPrint').style = 'min-width: 1024px'
            this.Stacked = false;
            if (this.$refs.dropdown) this.$refs.dropdown.hide(true);
            await this.$nextTick();
            await PrinterWindowHelper(
              document.querySelector("#divPrint").innerHTML
            );
            this.Stacked = "sm";
            this.$refs.tb.refresh();
          },
        },
        {
          onEdit: false,
          text: "Sair",
          icon: '<i class="fas fa-door-open"></i>',
          action: () => {
            console.log("send close");
            this.$emit("close");
          },
        },
      ],
      salvando: false,
      loading: false,
      ajustandoEstoque: false,
      ajusteDetalhe: null,
    };
  },
  watch: {
    "obj.estoque": {
      deep: true,
      handler() {
        if (this.obj.estoque && this.obj.estoque.length > 0) {
          //this.loading = true;
          // this.$nextTick(() => {
          //   this.obj.estoque
          //     .filter((x) => x.status == "OK")
          //     .forEach((it) => {
          //       it._rowVariant = "success";
          //     });
          //   this.obj.estoque
          //     .filter((x) => x.status == "OK" && x.qtd != x.qtd_conf)
          //     .forEach((it) => {
          //       it._rowVariant = "info";
          //     });
          // });
          //this.loading = false;
        }
      },
    },
  },
  computed: {
    est() {
      this.obj.estoque
        .filter((x) => x.status == "OK")
        .forEach((it) => {
          it._rowVariant = "success";
        });
      this.obj.estoque
        .filter((x) => x.status == "OK" && x.qtd != x.qtd_conf)
        .forEach((it) => {
          it._rowVariant = "info";
        });

      return this.obj.estoque;
    },
  },
  methods: {
    openAjusteDetail(row) {
      this.ajusteDetalhe = row.item.log;
      if (this.ajusteDetalhe) {
        this.ajusteDetalhe = this.ajusteDetalhe.map((it) => ({
          ...it,
          produto: this.produtosDisponiveis.find(
            (x) =>
              x.cod_produto == it.cod_produto &&
              x.cod_ingrediente == it.cod_ingrediente
          )?.nome,
        }));
      }
      this.$bvModal.show("modal-ajuste-detail");
    },
    ajustarEstoque() {
      this.$swal({
        title: "TEM CERTEZA?",
        text: "Você tem certeza que deseja ajustar o estoque para os valores contados neste inventário? Esta operação não poderá ser desfeita.",
        showCancelButton: true,
        icon: "question",
        confirmButtonText: "AJUSTAR ESTOQUE!",
        cancelButtonText: "Cancelar",
      }).then(async (x) => {
        if (x.isConfirmed) {
          this.ajustandoEstoque = true;
          this.obj.realizarAjuste = true;
          await this.salvar();
          await this.carregar(this.obj);
          this.ajustandoEstoque = false;
        }
      });
    },
    adicionarProduto() {
      if (this.produtoSelecionado) {
        if (
          this.est.some(
            (x) =>
              x.cod_ingrediente === this.produtoSelecionado.cod_ingrediente &&
              x.cod_produto === this.produtoSelecionado.cod_produto
          )
        ) {
          this.$swal(
            "Atenção!",
            "O produto escolhido já existe no inventário!",
            "error"
          );
          return;
        }
        this.est.push(
          Object.assign(
            {},
            {
              ...this.produtoSelecionado,
              qtd: 0,
              qtd_conf: 0,
              status: "Pendente",
            }
          )
        );
      }
      this.cancelProduto();
    },
    cancelProduto() {
      this.produtoSelecionado = null;
      this.$bvModal.hide("modal-add-prod");
    },
    addProd() {
      this.$bvModal.show("modal-add-prod");
    },
    getSinal(row) {
      let conta = row.item.qtd - row.item.qtd_conf;
      return conta < 0 ? "+" : "";
    },
    async carregar(obj) {
      console.log("iniciando busca", obj);
      if (obj.id) {
        this.loading = true;
        await this.$nextTick();
        try {
          let res = await EstoqueLib.getInvetario(obj);
          console.log("getInvetario", res);
          if (res && res.success) {
            this.obj = res.data[0];
            if (this.obj && this.obj.centro_custos) {
              this.obj.centro_custos = this.centroCustos.find(
                (x) =>
                  x.cod_custo.toString() == this.obj.centro_custos.toString()
              );
            }
          }
        } catch (errorLog) {
          console.log("ERROR ON CARREGAR INVETN", errorLog);
        }
        this.loading = false;
      }
    },
    async salvar(finalizar = false) {
      this.salvando = true;
      try {
        this.obj.updated_at = moment().format("YYYY-MM-DD HH:mm:ss");
        this.obj.cod_usuario = this.user.cod_usuario;
        if (finalizar) {
          this.obj.dt_finalizado = moment().format("YYYY-MM-DD HH:mm:ss");
          this.obj.cod_usuario_finalizado = this.user.cod_usuario;
          this.obj.usuarioFinalizou = this.user.nome;
        }
        await EstoqueLib.storeInventario({
          ...this.obj,
          centro_custos: this.obj.centro_custos.cod_custo,
        });
        this.$swal.fire({
          title: "Salvo com sucesso!",
          icon: "success",
          toast: true,
          timer: 1000,
          position: "top-end",
          showConfirmButton: false,
        });
      } catch (error) {
        console.log("erro on salvar inventario", error);
      }
      this.salvando = false;
    },
    marcarZerados() {
      if (this.obj && this.obj.estoque && this.obj.estoque.length > 0) {
        this.obj.estoque
          .filter((x) => x.qtd == 0 && x.status != "OK")
          .forEach((x) => {
            x.status = "OK";
          });
      }
    },
    resetItem(row) {
      row.status = "Pendente";
      row._rowVariant = "";
    },
    async checkAndCopy(row) {
      row.qtd_conf = row.qtd;
      row.status = "OK";
      this.salvar();
    },
    async checkOk(row) {
      //row.qtd_conf = row.qtd;
      row.status = "OK";
      this.salvar();
    },
    async gerar() {
      if (!this.obj.centro_custos || this.obj.centro_custos.length <= 0) {
        this.$swal(
          "Atenção",
          "Informe ao menos um centro de custo para continuar!",
          "error"
        );
        return;
      }
      this.gerando = true;
      try {
        let res = await EstoqueLib.criarInventario(this.obj.centro_custos);
        console.log("res", res);
        if (res && res.success) {
          this.obj = res.data[0];
          if (this.obj && this.obj.centro_custos) {
            this.obj.centro_custos = this.centroCustos.find(
              (x) => x.cod_custo.toString() == this.obj.centro_custos.toString()
            );
          }
          this.$swal.fire({
            title: "Gerado com sucesso!",
            icon: "success",
            toast: true,
            timer: 1000,
            position: "top-end",
            showConfirmButton: false,
          });
        }
      } catch (error) {
        console.log("ERROR ON GERAR", error);
      }
      this.gerando = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.listaEstoque {
  @media only screen and (max-width: 575px) {
    overflow: hidden;
    overflow-y: auto;
    position: fixed;
    top: 280px;
    bottom: 60px;
    left: 5px;
    right: 5px;
    padding-right: 10px;
    border-top: 3px solid #f5f5f5;
    // background-color: #f9f9f9;
    // border: 1px solid #f9f9f9;
  }
}
</style>
